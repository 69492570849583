<template>
    <b-modal :id="modalId" title="Add Ecosystems">
        <multiselect
            class="multiselect--complex multiselect-mid"
            v-model="selectedEcosystems"
            :options="ecosystemsList"
            select-label=""
            deselect-label=""
            label="name"
            track-by="id"
            :multiple="true"
            placeholder="Start typing..."
            @search-change="getEcosystems"
            :clear-on-select="false"
            :preserve-search="true"
        >
            <template slot="option" slot-scope="props">
                <img :src="LogoHelper.getLogo(props.option.logo)"
                     style="width:40px" alt=""> {{ props.option.name }}
            </template>
        </multiselect>

        <template #modal-footer>
            <b-button variant="primary" @click="addEcosystems" size="lg" :disabled="!ecosystemsList.length">Add
            </b-button>
        </template>
    </b-modal>
</template>

<script>
    import multiselect from "vue-multiselect"
    import ecosystems from "@/services/ecosystems.service"
    import trackersCompanies from "@/services/trackers-companies.service"

    export default {
        props: {
            trackerId: Number
        },

        components: {
            multiselect,
        },

        data() {
            return {
                modalId: 'add-ecosystems',
                selectedEcosystems: [],
                ecosystemsList: [],
            }
        },

        methods: {
            getEcosystems(search = '') {
                ecosystems.getList(search, this.trackerId).then(response => {
                    this.ecosystemsList = response.data
                })
            },

            open() {
                this.selectedEcosystems = []
                this.getEcosystems()
                this.$bvModal.show(this.modalId)
            },

            addEcosystems() {
                let ecosystemsIds = this.arrayHelper.arrayColumn(this.selectedEcosystems, 'id')
                trackersCompanies.addCompanies([this.trackerId], ecosystemsIds).then(() => {
                    this.$emit('ecosystemsAdded')
                    this.$bvModal.hide(this.modalId)
                })
            }
        }
    }
</script>
